<template>
  <div class="timeline-grid-container timeline-grid h-full pb-20 overflow-hidden">
    <TimelineSidebar
      :layers="layers"
      :playback-rate="playbackRate"

      @reorder-layers="$emit('reorder-layers', $event)"
      @toggle-row="$emit('toggle-row', $event)"
      @duplicate-layer="$emit('duplicate-layer', $event)"
      @enable-layer="$emit('enable-layer', $event)"
      @lock-layer="$emit('lock-layer', $event)"
      @rename-layer="$emit('rename-layer', $event)"
      @trash-layer="$emit('trash-layer', $event)"
      @new-layer="$emit('new-layer', $event)"
      @new-module="$emit('new-module', $event)"
      @increase-playbackrate="$emit('increase-playbackrate', $event)"
      @decrease-playbackrate="$emit('decrease-playbackrate', $event)"
      @jump-to-time-relative="$emit('jump-to-time-relative', $event)"
      @jump-to-prev="$emit('jump-to-prev', $event)"
      @jump-to-next="$emit('jump-to-next', $event)"
    />

    <TimelineLayers
      class="overflow-y-scroll"
      :scale="scale"
      :scroll="scroll"
      :layers="layers"
      :modules="modules"
      :duration="duration"
      :current-progresspc="currentProgresspc"
      :show-debug="showDebug"
      :active-module-id="activeModuleId"
      @view-module="$emit('view-module', $event)"
      @mouse-down="$emit('mouse-down', $event)"
      @update-scroll="$emit('update-scroll', $event)"
      @timeline-to-pc="$emit('timeline-to-pc', $event)"
      @drag-move-module="$emit('drag-move-module', $event)"
      @drag-move-module-end="$emit('drag-move-module-end', $event)"
      @drag-move-module-start="$emit('drag-move-module-start', $event)"
      @new-module="$emit('new-module', $event)"
      @updated-client-scale="$emit('updated-client-scale', $event)"
    />
  </div>
</template>
<script>
const TimelineSidebar = () => import('./TimelineSidebar')
const TimelineLayers = () => import('./TimelineLayers')

export default {

  components: {
    TimelineSidebar,
    TimelineLayers
  },

  props: ['scale', 'layers', 'modules', 'duration', 'scroll', 'currentProgresspc', 'showDebug', 'playbackRate', 'activeModuleId']

}
</script>
